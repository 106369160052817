import React from "react"
import styled from "styled-components/macro"
import { Link } from "gatsby"

const Header:React.FC<{location:string}> = ({location}) => {
  return (
  <header>
    <MaxWidthWrapper>
      <HeaderTitle>
        <TitleLink href="/">Stacy Vicknair {location}</TitleLink>
      </HeaderTitle>
      <HeaderActions>
        <HeaderLink to="/" activeClassName="active">Home</HeaderLink>
        <HeaderLink to="/resources/" activeClassName="active">Resources</HeaderLink>
        {/* <HeaderLink>Contact</HeaderLink> */}
        <HeaderCallToAction href="mailto:stacy@stacyvicknair.com">Email Me!</HeaderCallToAction>
      </HeaderActions>
    </MaxWidthWrapper>
  </header>
  )
}

const MaxWidthWrapper = styled.div`
  display: flex;
  -webkit-box-pack: end;
  -webkit-box-align: baseline;
  align-items: center;
  min-height: 64px;
  background-color: var(--color-primary);
  font-size: 1rem;
  padding: 0 16px;
`

const HeaderTitle = styled.div`
  flex: 1;
`

const TitleLink = styled.a`
font-weight: var(--font-weight-bold);
font-size: 1.3rem;
text-decoration: none;
color: var(--color-gray-1000);
  cursor: pointer;
  outline: none;
`

const HeaderActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

const HeaderLink = styled(Link)`
  font-weight: var(--font-weight-bold);
  opacity: 0.85;
  transition: opacity 400ms ease 0s;
  color: var(--color-gray-1000);
  padding: 8px 20px;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    transition: none;
    text-decoration: none;
  }
`

const HeaderCallToAction = styled.a`
font-weight: var(--font-weight-bold);
color: var(--color-gray-1000);
padding: 8px 20px;
border: 2px solid var(--color-secondary);
background: transparent;
border-radius: 6px;
cursor: pointer;
outline: none;
text-decoration: none;
`

export default Header