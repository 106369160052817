import React from "react"
import Header from "./header";
import styled from "styled-components";
import GlobalStyles from "./global-styles";
import GridContainer from "./grid-container";

const Layout:React.FC<{children: React.ReactNode}> = ({ children }) =>
{
  return (
  <Wrapper>
    <Header />
    <GridContainer>{children}</GridContainer>
    <GlobalStyles />
    </Wrapper>
    )
}

const Wrapper = styled.div`
  min-height: 100vh;
`

export default Layout;