import React from "react";
import styled from "styled-components";

const GridContainer = styled.main`
display: grid;
grid-template-columns:
  1fr
  1fr
  min(75ch, 100%)
  1fr
  1fr;
`

export default GridContainer;