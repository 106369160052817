import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`
  /*
  1. Use a more-intuitive box-sizing model.
  */
  *, *::before, *::after {
    box-sizing: border-box;
  }
  /*
    2. Remove default margin
  */
  * {
    margin: 0;
  }
  /*
    3. Allow percentage-based heights in the application
  */
  html, body {
    height: 100%;
  }
  /*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
  */
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  /*
    6. Improve media defaults
  */
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  /*
    7. Remove built-in form typography styles
  */
  input, button, textarea, select {
    font: inherit;
  }
  /*
    8. Avoid text overflows
  */
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }
  /*
    9. Create a root stacking context
  */
  #root, #__next {
    isolation: isolate;
  }
  
  html {
    --font-weight-bold: 600;
    --font-weight-medium: 400;
    --font-weight-light: 300;

    --font-family: "Open Sans",Futura,-apple-system,sans-serif;
    --font-family-mono: "Fira Mono",monospace;

    --color-text: hsl(0deg,0%,100%);
    --color-background: hsl(274deg 16% 8%);
    --color-blurred-background: hsla(210deg,30%,8%,0.85);

    --color-primary: hsl(230deg,33%,21%);
    --color-secondary: hsl(333deg,100%,52%);
    --color-tertiary: hsl(53deg,100%,50%);

    --color-decorative: hsl(200deg,50%,60%);
    --color-muted: hsl(210deg,38%,15%);

    --color-error: hsl(340deg,95%,60%);
    --color-error-background: hsla(340deg,95%,43%,0.1);

    --color-success: hsl(160deg,100%,40%);
    --color-success-background: hsla(160deg,100%,40%,0.1);

    --color-alert: hsl(30deg,100%,50%);
    --color-alert-background: hsla(38deg,100%,50%,0.1);

    --color-gray-50: hsl(210deg, 19%, 10%);
    --color-gray-100: hsl(210deg, 15%, 20%);
    --color-gray-200: hsl(210deg, 15%, 25%);
    --color-gray-300: hsl(210deg, 10%, 40%);
    --color-gray-400: hsl(210deg, 9%, 45%);
    --color-gray-500: hsl(210deg, 8%, 50%);
    --color-gray-600: hsl(210deg, 12%, 55%);
    --color-gray-700: hsl(210deg, 14%, 66%);
    --color-gray-800: hsl(210deg, 20%, 77%);
    --color-gray-900: hsl(210deg, 25%, 88%);
    --color-gray-1000: hsl(210deg, 25%, 96%);

    --color-buy: hsl(333deg 100% 45%);
    --color-buy-shadow: hsl(333deg 100% 40%);

    --color-link: hsl(250deg 100% 77.5%);

    --selection-background-color: hsl(0deg 0% 100% / 1);
    --selection-text-color: hsl(0deg 0% 0% / 1);

    --gradient-buy: linear-gradient( 245deg, hsl(333deg 100% 52%) 5%, hsl(333deg 100% 40%) 90% );

    --reach-dialog: 1;

    --media-breakpoint-desktop: 1200px;
    --media-breakpoint-tablet: 992px;
  }

  body {
    font-family: var(--font-family);
    background-color: var(--color-gray-1000);
  }
`;

export default GlobalStyles;